import React, { Component } from 'react'

import Layout from '@components/layout/Layout'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import ReferenceComponent from '@components/references/reference.component'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import ReadNext from '@components/read-next-button/read-next-button'
import GrayCallout from '@components/gray-callout/gray-callout'

import iconEducation from '@assets/images/infographics/infographic-icon-education.svg'
import iconReoccurring from '@assets/images/infographics/infographic-icon-recocurring-assessments.svg'
import iconCbtn from '@assets/images/infographics/infographic-icon-cbt-n.svg'
import iconPsychosocial from '@assets/images/infographics/infographic-icon-psychosocial-implications.svg'
import searchIcon from '@assets/images/icons/icon-magnifying-glass.svg'

import { references } from '@components/references/management-considerations-references'
import { liveRampEventTracking } from '../../../app-utils'
import { trackGa } from '@components/GoogleTracking'

import './treatment-decisions.scss'

class TreatmentDecisions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showLeavingSiteModal: false,
      href: '',
      trackModalContinue: '',
      trackModalCancel: '',
    }

    this.handleShowModalClicked = this.handleShowModalClicked.bind(this)
    this.handleHideModal = this.handleHideModal.bind(this)
    this.openLink = this.openLink.bind(this)
    this.openBottomLink = this.openBottomLink.bind(this)
  }

  componentDidMount() {
    liveRampEventTracking('management-considerations-page-view')
  }

  handleHideModal() {
    this.setState({
      showLeavingSiteModal: false,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }

  openLink(link) {
    trackGa('management considerations, click, xywavhcptreatmentoption - management considerations')
    this.setState(() => {
      return {
        href: link,
        trackModalContinue: 'management considerations, click, xywavhcp brand site continue - exit overlay top cta',
        trackModalCancel: 'management considerations, click, xywavhcp brand site cancel - exit overlay top cta'
      }
    }, this.handleShowModalClicked())
  }

  openBottomLink(link) {
    trackGa('management considerations, click, xywavhcp - management considerations')
    this.setState(() => {
      return {
        href: link,
        trackModalContinue: 'management considerations, click, xywavhcp brand site continue - exit overlay',
        trackModalCancel: 'management considerations, click, xywavhcp brand site cancel - exit overlay'
      }
    }, this.handleShowModalClicked())
  }

  render() {

    const learnMoreContent = {
      title: 'Explore more about an FDA-approved treatment option that may be right for your patients',
      externalLink: true,
      link: 'https://www.xywavhcp.com/',
      linkLabel: 'Discover the treatment option',
      className: 'learn-more-wrapper-gray',
      tracking: 'management considerations,	click, xywavhcptreatmentoption - management considerations',
    }

    const grayCallout = {
      text: 'Explore more about an FDA-approved treatment option that may be right for your patients',
      buttonText: 'LEARN MORE',
    }

    const readNext = {
      readNextContent: 'Stay connected',
      goTo: '/stay-connected/connect-with-us/',
      tracking: 'management considerations, click,	stay connected - management considerations',
    }

    return (
      <Layout addedClass='page-disease-management' location={this.props.location}>
        <div
          id="background-wrapper"
          className="page-background-wrapper"
          role="img"
          alt="Cloudy open terrain path with boulders"
        >
          <div className="primary-container">
            <h1 className="hero-title">
              Considerations for <span className="inline-block">Disease Management</span>
            </h1>
            <h3 className="hero-subtitle">
              A comprehensive approach to long-term narcolepsy management is important.<sup>1-3</sup>
            </h3>
          </div>
        </div>

        <div className="feature-wrapper">
          <div className="primary-container">
            <div className="collapsible-section">
              <div className="collapsible-section-header">
                <h2 className="collapsible-header-title">Management Considerations</h2>
                <LearnMoreComponent learnMoreContent={learnMoreContent} externalLinkHandler={this.openLink}/>
              </div>
            </div>
          </div>

          <div className="primary-container">
            <div className="primary-card treatment-narcolepsy-card">
              <p className="body-copy">
                The AASM has developed quality measures for the care of patients with narcolepsy, which include
                reduction of excessive daytime sleepiness and improved accuracy of diagnosis as desired outcomes. To
                help improve EDS, the AASM recommends quantifying daytime sleepiness with a validated scale at every
                visit and initiating treatment within a month of narcolepsy diagnosis. Improved accuracy of diagnosis
                should be achieved by documenting a comprehensive sleep history in patients with narcolepsy and using
                standardized objective sleep assessments, such as PSG and MSLT.<sup>4</sup>
              </p>

              <div className="card-container-disease-mgmt">
                <div className="header-disease-mgmt">
                  <p>Behavioral Approaches to Disease Management<sup>5</sup></p>
                </div>
                
                <div className="body-disease-mgmt">
                  <div className="content-disease-mgmt">
                    <div className="image-container">
                      <img
                        // className="cropped-img"
                        id="icon-edu"
                        src={iconEducation}
                        alt="Education icon for 4 behavioral approaches to disease management"
                      />
                    </div>
                    <div className="text-content">
                      <p>
                        <span>Education</span> - Highlight the importance of treatment adherence, maintaining good sleep hygiene including strategic napping; lifestyle reorganization; behavioral techniques
                      </p>
                    </div>
                  </div>

                  <div className="content-disease-mgmt">
                    <div className="image-container">
                      <img
                        // className="cropped-img"
                        id="icon-reoccurring"
                        src={iconReoccurring}
                        alt="Reoccuring Assessment icon for 4 behavioral approaches to disease management"
                      />
                    </div>
                    <div className="text-content">
                      <p>
                        <span>Reoccurring Assessments</span> - Objective, subjective, or self-report; regular evaluation of functioning and treatment effects; using sleep actigraphs and/or sleep logs; administering Epworth Sleepiness Scale, Maintenance of Wakefulness Test, Stanford Sleepiness Scale, or other related validated measure(s)
                      </p>
                    </div>
                  </div>

                  <div className="content-disease-mgmt">
                    <div className="image-container">
                      <img
                        // className="cropped-img"
                        id="icon-cbtn"
                        src={iconCbtn}
                        alt="CBT-N icon for 4 behavioral approaches to disease management"
                      />
                    </div>
                    <div className="text-content">
                      <p>
                        <span>CBT-N</span> - Techniques targeting the behavioral management of narcolepsy symptoms: talk therapy targeting distorted cognitions, treatment adherence, taking medications at appropriate times, maintaining good sleep hygiene, taking scheduled naps, imagery, and hypnosis
                      </p>
                    </div>
                  </div>

                  <div className="content-disease-mgmt">
                    <div className="image-container">
                      <img
                        // className="cropped-img"
                        id="icon-pyschosocial"
                        src={iconPsychosocial}
                        alt="CBT-N icon for 4 behavioral approaches to disease management"
                      />
                    </div>
                    <div className="text-content">
                      <p>
                        <span>Psychosocial Implications</span> -  Occupational considerations and professional advocacy (eg, legal rights); available resources in the community, such as a Narcolepsy Support Group
                      </p>
                    </div>
                  </div>

                </div>
              </div>
              <p>
                AASM, American Academy of Sleep Medicine; CBT-N, cognitive behavioral therapy for narcolepsy; EDS,
                excessive daytime sleepiness; MSLT, multiple sleep latency test; PSG, polysomnography
              </p>
              <div className="description">
                <div className="tertiary-header">
                  Narcolepsy is a chronic neurologic condition that usually warrants long-term management.
                  <sup>1,6,7</sup>
                </div>
                <div className="content">
                  <div>
                    <p>
                    The increased frequency of comorbidities among patients with this condition should be considered
                    when developing management strategies.<sup>1,2,8</sup> Comprehensive evaluation that assesses both symptoms and coexisting medical and psychiatric comorbidities is crucial to narcolepsy management.
                    <sup>2,3,9</sup>
                    </p>
                    <p>As part of a management plan, lifestyle changes may also be necessary to help address the risk of comorbidities.<sup>2,10,11</sup> This highlights the need to look beyond symptom control and consider broader lifelong health implications when managing patients with narcolepsy.<sup>1,2,6,10</sup></p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="primary-container">
          <ReadNext readNext={readNext} />
          <GrayCallout
            preText={<div className="callout-search-icon"><img src={searchIcon} /></div>}
            grayCallout={grayCallout}
            onClick={() => {
              this.openBottomLink('https://www.xywavhcp.com/')
              liveRampEventTracking('FDA-approved-learn-more-click')
            }}
            className="fancy-background-callout"
          />
        </div>

        <ReferenceComponent referenceList={references} />
        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
          modalType={'xywaveHCP'}
          tracking={this.state.trackModalContinue}
          cancelTracking={this.state.trackModalCancel}
          exitTracking={'management considerations, click, xywavhcp exit overlay views - management considerations'}
          liveRampTrackingString={'xywav-leaving-site-continue-click'}
        />
      </Layout>
    )
  }
}

export default TreatmentDecisions
