export const references = [
  {
    content: 'Thorpy MJ, Hiller G. The medical and economic burden of narcolepsy: implications for managed care. ',
    source: 'Am Health Drug Benefits. ',
    info: '2017;10(5):233-241.',
  },
  {
    content:
      'Morse AM. Narcolepsy in children and adults: a guide to improved recognition, diagnosis and management. ',
    source: 'Med Sci (Basel). ',
    info: '2019;7(12):106.',
  },
  {
    content:
      'Wise MS, Arand DL, Auger RR, Brooks SN, Watson NF; American Academy of Sleep Medicine. Treatment of narcolepsy and other hypersomnias of central origin. ',
    source: 'Sleep. ',
    info: '2007;30(12):1712-1727.',
  },
  {
    content: 'Krahn LE, Hershner S, Loeding LD, et al. Quality measures for the care of patients with narcolepsy.',
    source: 'J Clin Sleep Med.',
    info: '2015;11(3):335-355.',
  },
  {
    content: 'Bhattarai J, Sumerall S. Current and future treatment options for narcolepsy: a review.',
    source: ' Sleep Sci.',
    info: ' 2017;10(1):19‐27',
  },
  {
    content:
      'Thorpy M, Morse AM. Reducing the clinical and socioeconomic burden of narcolepsy by earlier diagnosis and effective treatment. ',
    source: 'Sleep Med Clin. ',
    info: '2017;12(1):61-71.',
  },
  {
    content: 'National Institute of Neurological Disorders and Stroke. Narcolepsy fact sheet. ',
    source: '',
    info:
      'https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Fact-Sheets/Narcolepsy-Fact-Sheet. Accessed November 10, 2020.',
  },
  {
    content:
      'Black J, Reaven NL, Funk SE, et al. Medical comorbidity in narcolepsy: findings from the Burden of Narcolepsy Disease (BOND) study. ',
    source: 'Sleep Med. ',
    info: '2017;33:13-18.',
  },
  {
    content:
      'Krahn LE, Zee PC, Thorpy MJ. Current understanding of narcolepsy 1 and its comorbidities: what clinicians need to know.',
    source: ' Adv Ther. ',
    info: '2022;39(1):221-243.',
  },

  {
    content:
      'Thorpy MJ, Dauvilliers Y. Clinical and practical considerations in the pharmacologic management of narcolepsy. ',
    source: 'Sleep Med. ',
    info: '2015;16(1):9-18.',
  },
  {
    content:
      'de Biase S, Gigli GL, Valente M. Important decisions in choosing the pharmacotherapy for narcoleptics. ',
    source: 'Expert Opin Pharmacother. ',
    info: '2019;20(5):483-486.',
  },
]